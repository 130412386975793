.uploadBtn {
    position: relative;
    top: -6px;
    margin-left: 10px;
}.imageRemoveBtn {
    position: absolute;
    top: 0;
    right: 0;
}.boxedImage {
    max-height: 150px;
    display: block;
    margin: 5px auto;
}
.boxedImageSmall {
    height: 40px;
    margin: 0 5px 0 0;
}