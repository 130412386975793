.slides{
    height: 100vh;    
}

.swiper-pagination-bullet{
    border: 1px solid #fff;
}

.slide{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.slides img{
    max-width: 50vw;
    max-height: 50vh;
}

.signup{
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
}
.services{
    background: var(--ion-color-success);
    color: var(--ion-color-success-contrast);
}
.alerts{
    background: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);
}
.queue{
    background: var(--ion-color-warning);
    color: var(--ion-color-warning-contrast);
}
.scan{
    background: var(--ion-color-light);
    color: var(--ion-color-light-contrast);
}
.customer{
    background: var(--ion-color-dark);
    color: var(--ion-color-dark-contrast);
}
.welcome{
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
}